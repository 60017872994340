<template>
  <v-list-item v-if="shouldCreate">
    <!-- 橫式(預設) -->
    <template v-if="!rowStyle">
      <v-list-item-title>
        {{title}}
      </v-list-item-title>

      <v-list-item-title class="text-right">
        {{data}}
      </v-list-item-title>

      <v-list-item-action v-if="copyable">
        <v-btn icon @click="copyData">
          <v-icon class="fa fa-copy"></v-icon>
        </v-btn>
      </v-list-item-action>
    </template>

    <!-- 直式 -->
    <template v-if="rowStyle">
      <v-list-item-content>
        <v-list-item-title>
          {{title}}
        </v-list-item-title>

        <v-list-item-subtitle v-if="subtitle">
          {{subtitle}}
        </v-list-item-subtitle>

        <v-list-item-subtitle>
          <span v-if="!dataEmpty">{{data}}</span>
          <span v-if="dataEmpty">{{emptyText}}</span>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action v-if="copyable">
        <v-btn icon @click="copyData">
          <v-icon class="fa fa-copy"></v-icon>
        </v-btn>
      </v-list-item-action>
    </template>

   <div v-if="item.actionSlot" class="ml-4">
     <component
       :is="item.actionSlot"
       :form-key="formKey"
     ></component>
   </div>
  </v-list-item>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    index: Number,
    item: Object,
    formKey: String,
  },
  methods: {
    copyData() {
      this.$helper.copyText(this.dataForCopy)
    },
  },
  computed: {
    dataForCopy() {
      return this.data
    },
    title() {
      return this.$t(this.item.title)
    },
    subtitle() {
      return this.$t(this.item.subtitle)
    },
    data() {
      let data = typeof this.item.data === 'function'
        ? this.item.data(this.formInfo)
        : this.item.data

      if(data == 0) return 0
      if(!data) return null
      return this.$t(data)
    },
    dataEmpty() {
      console.warn('data', this.data)
      if(this.data == null) return true
      if(this.data == undefined) return true
      if(this.data == '') return true
      return false
    },
    rowStyle() {
      return this.item.row === true
    },
    copyable() {
      if(!this.dataForCopy) return false
      return this.item.copyable === true
    },
    shouldCreate() {
      if(!this.hiddenIfEmpty) return true
      return !!this.data
    },
    hiddenIfEmpty() {
      return this.item.hiddenIfEmpty === true
    },
    emptyText() {
      return this.$t(this.item.emptyText) || ''
    },
    formInfo() {
      return this.$store.getters[`form/${this.formKey}/info`]
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
